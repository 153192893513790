import UUID from '@/classes/helpers/UUID'

export default class WebSocketClient
{
    constructor( core )
    {

        if( !WebSocketClient.instance )
        {

            this.config = core.getConfig()
            this.store = core.getStore()
            this.eventManager = core.getEventManager()

            this.heartbeatTimeout = null
            this.uuid = new UUID()
            this.timeout = 30000
            this.messageTimeout = 10000
            this.onConnectTimeout = false
            this.connected = false
            this.client = this.setup()
            this.resetted = false

            this.eventManager.add( 'reset-websocket', () =>
            {
                this.checkReset()
            } )

            this.queue = {}

            WebSocketClient.instance = this

        }

        return WebSocketClient.instance

    }

    setup()
    {

        let client = new WebSocket( this.config.socketUrl, 'json' )

        client.onmessage = ( message =>
        {
            this.handleMessage( message )
        } )
        client.onopen = ( () =>
        {
            this.resetted = false
            this.store.commit( 'setOnline', 1 )
            window.connectionStatus = 'online'
            this.heartbeat( 'open' )
            this.eventManager.dispatch( 'on-websocket-open' )
        } )
        client.onclose = ( () =>
        {
            this.store.commit( 'setOnline', 0 )
            window.connectionStatus = 'offline'
            this.eventManager.dispatch( 'on-websocket-close' )
            this.reset()
        } )
        client.onerror = ( () =>
        {
            this.handleError()
        } )

        return client

    }

    handleError()
    {

        if( null !== this.client )
        {
            switch( this.client.readyState )
            {
                case 0:
                case 1:
                case 2:
                    break
                case 3:
                    if( !this.resetted )
                    {
                        this.resetted = true
                        this.reset( true )
                    }
                    break

            }
        }
        else
        {
            console.log( 'error: client requested while in active connection reset' )
        }

    }

    reset( direct )
    {

        clearTimeout( this.heartbeatTimeout )

        this.connected = false
        this.heartbeatTimeout = null
        delete this.client
        this.client = null
        this.flushQueue()

        setTimeout( () =>
        {

            this.client = this.setup()

        }, ( direct !== undefined ? 100 : 3000 ) )

    }

    /*eslint-disable*/
    flushQueue()
    {

        for( const [ key, value ] of Object.entries( this.queue ) )
        {
            this.eventManager.remove( 'onSocketTimeout-' + key )
            this.eventManager.remove( 'onSocketMessage-' + key )
            delete this.queue[ key ]
        }

        this.queue = {}

    }

    /*eslint-enable*/

    checkReset( force )
    {
        if( ( !this.connected
              || this.store.getters.online === 0
              || this.heartbeatTimeout === null
              || undefined !== force )
            && !this.resetted )
        {
            this.resetted = true
            this.reset( true )
        }
    }

    heartbeat( which )
    {

        if( 'open' === which )
        {
            this.connected = true
            this.eventManager.dispatch( 'onSocketConnect' )
        }

        if( 'tick' === which )
        {
            this.eventManager.dispatch( 'on-wss-tick' )
        }

        clearTimeout( this.heartbeatTimeout )
        this.heartbeatTimeout = null

        this.heartbeatTimeout = setTimeout( () =>
        {

            this.reset()

        }, ( this.timeout + 1000 ) )

    }

    request( requestMessage, timeout, prepared )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( null !== this.client
                && 1 === this.client.readyState )
            {

                if( undefined === prepared )
                {
                    requestMessage.id_session = this.store.getters.idSession
                    requestMessage.appVersion = this.config.versionNumber
                }

                let messageUuid = this.uuid.generate()
                requestMessage.messageId = messageUuid

                let message = JSON.stringify( requestMessage )
                this.queue[ messageUuid ] = requestMessage
                this.eventManager.add( 'onSocketMessage-' + messageUuid, ( response ) =>
                {

                    this.eventManager.remove( 'onSocketTimeout-' + messageUuid )
                    return resolve( this.handleResponse( response ) )

                } )

                this.eventManager.add( 'onSocketTimeout-' + messageUuid, ( response ) =>
                {

                    this.eventManager.remove( 'onSocketMessage-' + messageUuid )
                    return resolve( this.handleResponse( response ) )

                } )

                setTimeout( () =>
                {

                    this.eventManager.dispatchAndRemove( 'onSocketTimeout-' + messageUuid, { state: false } )

                }, ( timeout ? timeout : this.messageTimeout ) )

                this.client.send( message )

            }
            else
            {
                return reject( 'ERR_NOT_CONNECTED' )
            }

        } )

    }

    handleResponse( response )
    {

        return new Promise( ( resolve, reject ) =>
        {

            delete this.queue[ response.messageId ]

            if( response.state !== false )
            {
                return resolve( response )
            }
            else
            {
                return reject( 'WSS_INVALID_RESPONSE' )
            }

        } )

    }

    handleMessage( messageEvent, retry )
    {

        let payload = JSON.parse( messageEvent.data )
        this.heartbeat( payload.method )

        switch( payload.state !== false )
        {
            case true:
                if( undefined !== payload.data
                    && 'tick' !== payload.method )
                {
                    try
                    {

                        let data = payload.data
                        if( undefined !== data.messageId
                            && undefined !== this.queue[ data.messageId ] )
                        {

                            let response = data
                            response.messageTimestamp = payload.timestamp

                            this.eventManager.dispatchAndRemove( 'onSocketMessage-' + data.messageId, response )

                        }
                    }
                    catch( error )
                    {
                        if( !retry )
                        {
                            setTimeout( () =>
                            {
                                this.handleMessage( messageEvent, true )
                            }, 1000 )
                        }
                    }
                }
                break
            case false:
                if( undefined !== payload.messageId
                    && undefined !== this.queue[ payload.messageId ] )
                {

                    let response = undefined !== payload.data ? payload.data : {}
                    response.state = false
                    response.messageTimestamp = payload.timestamp
                    this.eventManager.dispatchAndRemove( 'onSocketMessage-' + payload.messageId, response )

                }
                break
        }


    }

}