export default class Rights
{

    constructor( core )
    {

        if( !Rights.instance )
        {

            this.roles = core.getConfig().roles
            this.store = core.getStore()
            Rights.instance = this

        }

        return Rights.instance

    }

    isRouteAllowed( route )
    {

        if( this.roles[ this.store.getters.idRole ].isWildcard )
        {
            return true
        }

        if( -1 < this.roles[ this.store.getters.idRole ].routes.indexOf( route ) )
        {
            return true
        }

        return false

    }

    isAllowed( what )
    {

        if( this.roles[ this.store.getters.idRole ].isWildcard )
        {
            return true
        }

        if( -1 < this.roles[ this.store.getters.idRole ].functions.indexOf( what ) )
        {
            return true
        }

        return false

    }

    isRouteRestricted( what )
    {

        if( this.roles[ this.store.getters.idRole ].isWildcard )
        {
            return false
        }

        if( -1 < this.roles[ this.store.getters.idRole ].restricted.indexOf( what ) )
        {
            return true
        }

        return false

    }

    hasFlag( which )
    {

        if( this.roles[ this.store.getters.idRole ].isWildcard )
        {
            return false
        }

        if( -1 < this.roles[ this.store.getters.idRole ].flags.indexOf( which ) )
        {
            return true
        }

        return false

    }

}