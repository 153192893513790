<template>
    <transition appear name="slide-left">
        <div class="overlay">
            <div class="content">
                <div class="title" v-if="!inlineForm">
                    <div class="headline" v-html="title"></div>
                    <div class="closer" @click="$emit('close')">
                        X
                    </div>
                </div>
                <div class="info">
                    <div v-if="errors !== false && errors !== undefined">
                        <div class="errorMessage">
                            <p v-html="errors"></p>
                        </div>
                    </div>
                    <template v-for="field in fields">
                        <template v-if="'hidden' !== field.type">
                            <div class="row-container" :key="field.id">
                                <div class="left">{{ field.caption }}</div>
                                <div class="right">
                                    <template v-if="field.type === 'TextBox'">
                                        <input type="text" :id="field.id" :value="field.value"
                                               :placeholder="field.placeholder" @click="checkDoubleClick"
                                               @input="checkHook( field.hook, $event )"/>
                                    </template>
                                    <template v-if="field.type === 'NumberBox'">
                                        <input type="number" :id="field.id" :value="field.value"
                                               :placeholder="field.placeholder" @click="checkDoubleClick"
                                               @input="checkHook( field.hook, $event )"/>
                                    </template>
                                    <template v-if="field.type === 'CheckBox'">
                                        <input type="checkbox" :id="field.id"
                                               :checked="field.value === 1"/>
                                    </template>
                                    <template v-if="field.type === 'TextArea'">
                                        <textarea :id="field.id" :value="field.value" :class="field.class"></textarea>
                                    </template>
                                    <template v-if="field.type === 'LicenseSelector'">
                                        <LicenseSelector :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'PeriodSelector'">
                                        <PeriodSelector :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'NewsletterRecipient'">
                                        <NewsletterRecipients :id="field.id"/>
                                    </template>
                                    <template v-if="field.type === 'NewsletterTemplate'">
                                        <NewsletterTemplate :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'ListTypeSelector'">
                                        <ListTypeSelector :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'FeatureSetSelector'">
                                        <FeatureSetSelector :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'RowAdder'">
                                        <RowAdder :id="field.id" :value="field.value" :rowType="field.rowType"
                                                  :setup="field.setup"
                                                  @values="handleRowAdderValues"/>
                                    </template>
                                    <template v-if="field.type === 'Separator'">
                                        <Separator :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'SlotSetup'">
                                        <SlotSetup :id="field.id" :value="field.value" :setup="field.setup"/>
                                    </template>
                                    <template v-if="field.type === 'RoleSelector'">
                                        <RoleSelector :id="field.id" :value="field.value"/>
                                    </template>
                                    <template v-if="field.type === 'OpenableSubCompetence'">
                                        <OpenableSubCompetence :id="field.id" :value="field.value"
                                                               :parent="field.parent"
                                                               @values="$emit( 'values', $event )"/>
                                    </template>
                                    <template v-if="field.type === 'Paragraph'">
                                        <p style="margin:0;" v-html="field.text"></p>
                                    </template>
                                </div>
                                <div class="clearfix"></div>
                            </div>
                        </template>
                        <template v-else>
                            <input type="hidden" :key="field.id" :id="field.id" :value="field.value"/>
                        </template>
                    </template>
                    <div class="row-container">
                        <div class="left">&nbsp;</div>
                        <div class="right"><input type="button" class="submit"
                                                  :value="subjectCaption ? subjectCaption : 'speichern'"
                                                  @click="handleSubmit"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import NewsletterRecipients  from '@/components/elements/formElements/NewsletterRecipients'
import NewsletterTemplate    from '@/components/elements/formElements/NewsletterTemplate'
import LicenseSelector       from '@/components/elements/formElements/LicenseSelector'
import PeriodSelector        from '@/components/elements/formElements/PeriodSelector'
import ListTypeSelector      from '@/components/elements/formElements/ListTypeSelector'
import FeatureSetSelector    from '@/components/elements/formElements/FeatureSetSelector'
//import RowAdder              from '@/components/elements/formElements/RowAdder'
import SlotSetup             from '@/components/elements/formElements/SlotSetup'
import RoleSelector          from '@/components/elements/formElements/RoleSelector'
import Separator             from "@/components/elements/formElements/Separator";
import OpenableSubCompetence from "@/components/elements/formElements/OpenableSubCompetence.vue";

export default {

    name      : 'Form',
    components: {
        OpenableSubCompetence,
        Separator,
        RoleSelector,
        SlotSetup,
//        RowAdder,
        FeatureSetSelector,
        ListTypeSelector,
        PeriodSelector,
        LicenseSelector,
        NewsletterTemplate,
        NewsletterRecipients
    },
    props     : [ 'formValues', 'errors', 'inline' ],

    data()
    {
        return {
            title         : '',
            subjectCaption: false,
            fields        : [],
            lastClick     : -1,
            inlineForm    : false
        }
    },

    created()
    {
        if( this.$props.inline === true )
        {
            this.inlineForm = true
        }
        else
        {
            this.title = this.$props.formValues.title
        }
        this.subjectCaption = this.$props.formValues.subjectCaption
        this.fields = this.$props.formValues.fields
    },

    methods: {

        checkDoubleClick( event )
        {
            if( this.lastClick === -1 )
            {
                this.lastClick = Date.now()
            }
            else
            {
                if( Date.now() - this.lastClick < 500 )
                {
                    this.$emit( 'doubleClick', event.target.id )
                }
                else
                {
                    this.lastClick = Date.now()
                }
            }
        },

        handleRowAdderValues( values )
        {
            for( let f in this.fields )
            {
                if( this.fields[ f ].id === values.id )
                {
                    this.fields[ f ].value = values.values
                }
            }
        },

        handleSubmit()
        {
            let values = {}
            for( let f in this.fields )
            {
                let elm = document.querySelector( '#' + this.fields[ f ].id )
                if( null !== elm )
                {
                    if( 'checkbox' !== elm.type )
                    {
                        values[ this.fields[ f ].id ] = elm.value
                    }
                    else
                    {
                        values[ this.fields[ f ].id ] = elm.checked === true
                    }
                }
                if( this.fields[ f ].type === 'RowAdder'
                    || this.fields[ f ].type === 'SlotSetup' )
                {

                    if( this.fields[ f ].value !== undefined
                        && this.fields[ f ].value !== null
                        && ( Array.isArray( this.fields[f].value ) && this.fields[ f ].value[0] !== 'create' ) )
                    {
                        values[ this.fields[ f ].id ] = this.fields[ f ].value
                    }
                    else
                    {

                        let i     = 0,
                            adder = []

                        elm = document.querySelector( '#value_' + i )
                        while( null !== elm )
                        {
                            if( undefined !== elm.value
                                && null !== elm.value
                                && '' !== elm.value.trim() )
                            {
                                adder.push( JSON.parse( elm.value ) )
                            }
                            i++
                            elm = document.querySelector( '#value_' + i )
                        }

                        values[ this.fields[ f ].id ] = adder

                    }
                }
            }
            this.$emit( 'submit', values )

        },

        checkHook( hook, event )
        {

            if( undefined !== hook )
            {
                let elm = document.querySelector( '#' + hook.id )
                if( null !== elm )
                {
                    switch( hook.type )
                    {
                        case 'cleanId':
                            elm.value = this.$core.getReformatter().cleanId( event.target.value )
                            break
                    }
                }
            }

        }

    }

}
</script>