<template>
    <div>
        <transition-group appear name="next">
            <ToolsTemplates v-if="tab === 'tools/templates'" key="templates"/>
            <ToolsNewsletter v-if="tab === 'tools/newsletter'" key="newsletter"/>
            <ToolsServer v-if="tab === 'tools/server'" key="server"/>
            <ToolsDeletions v-if="tab === 'tools/deletions'" key="deletions"/>
            <ToolsBounces v-if="tab === 'tools/bounces'" key="bounces"/>
            <ToolsAutomails v-if="tab === 'tools/automailer'" key="automails"/>
            <ToolsListtemplates v-if="tab === 'tools/listtemplates'" key="lists"/>
            <ToolsCompetenceTemplates v-if="tab === 'tools/competencetemplates'" key="competences"/>
            <ToolsToolkit v-if="tab === 'tools/toolkit'" key="toolkit"/>
            <!--<ToolsSlottemplates v-if="tab === 'tools/slottemplates'" key="slots"/>-->
            <ToolsMessages v-if="tab === 'tools/messages'" key="messaging"/>
        </transition-group>
    </div>
</template>

<script>
import ToolsTemplates           from '@/components/pages/tabs/tools/ToolsTemplates'
import ToolsNewsletter          from '@/components/pages/tabs/tools/ToolsNewsletter'
import ToolsServer              from './tabs/tools/ToolsServer'
import ToolsDeletions           from './tabs/tools/ToolsDeletions'
import ToolsBounces             from './tabs/tools/ToolsBounces'
import ToolsListtemplates       from '@/components/pages/tabs/tools/ToolsListtemplates'
/*import ToolsSlottemplates from '@/components/pages/tabs/tools/ToolsSlottemplates'*/
import ToolsCompetenceTemplates from "@/components/pages/tabs/tools/ToolsCompetenceTemplates.vue";
import ToolsAutomails           from "@/components/pages/tabs/tools/ToolsAutomails";
import ToolsMessages            from "@/components/pages/tabs/tools/ToolsMessages";
import ToolsToolkit             from "@/components/pages/tabs/tools/ToolsToolkit.vue";

export default {

    name      : 'Tools',
    components: {
        ToolsToolkit,
        ToolsMessages,
        ToolsAutomails,
        ToolsListtemplates,
        ToolsCompetenceTemplates,
        ToolsBounces,
        ToolsDeletions,
        ToolsServer,
        ToolsNewsletter,
        ToolsTemplates
    },
    data()
    {
        return {
            tab  : this.$core.getState( 'navigation.activeSub' ),
            timer: null
        }
    },

    created()
    {
        this.timer = setInterval( () =>
        {
            this.tab = this.$core.getState( 'navigation.activeSub' )
        }, 200 )
    },

    beforeDestroy()
    {
        clearInterval( this.timer )
        this.timer = null
    }

}
</script>