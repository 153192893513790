<template>
    <transition appear name="slide-left">
        <div class="fullscreen raised"
             :id="'reply-'+( undefined !== mail ? mail.id : 0 )" :key="'reply-'+( undefined !== mail ? mail.id : 0 )">
            <div v-if="true !== processing" class="content">
                <div class="title">
                    <div v-if="undefined !== mail" class="headline"
                         v-html="'Mail beantworten: <strong>'+mail.sender+'</strong> &bull; '+mail.subject"></div>
                    <div v-else class="headline"
                         v-html="'Nachricht schreiben'"></div>
                    <div class="closer" @click="$emit( 'close' )">
                        X
                    </div>
                </div>
                <ErrorMessage v-if="false !== error"
                              :title="error" :message="errorMessage" @click="clearError()"/>
                <div class="info padded">
                    <div class="form-row">
                        <div class="form-left">
                            <div class="default-button default full" @click="$emit( 'close' )">
                                abbrechen
                            </div>
                        </div>
                        <div class="form-right">
                            <div class="default-button submit full" @click="handleReply()">
                                senden
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">Vorlage</div>
                        <div class="form-right padded">
                            <select class="select-css inset" name="template" id="template">
                                <option value="default">Helpdesk-Standard</option>
                                <option value="clean">Clean (mit Footer)</option>
                            </select>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">Absender</div>
                        <div class="form-right padded"><strong>"{{ senders[ $store.getters.username ].sign }}" &lt;kontakt@entzettelt.de&gt;</strong>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">Empfänger</div>
                        <div class="form-right">
                            <template v-if="isReply">
                                <input class="padded" type="text" name="recipient" id="recipient"
                                       :value="recipient"/>
                            </template>
                            <template v-else>
                                <input class="padded" type="text" name="recipient" id="recipient"
                                       :value="recipient ? recipient : ''"/>
                            </template>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">Betreff</div>
                        <div class="form-right">
                            <template v-if="isReply">
                                <input class="padded" type="text" name="subject" id="subject"
                                       :value="( undefined !== mail ? 'Antwort: '+mail.subject : '' )"/>
                            </template>
                            <template v-else>
                                <input class="padded" type="text" name="subject" id="subject"/>
                            </template>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">Nachricht</div>
                        <div class="form-right">
                            <template v-if="isReply">
                                <textarea class="droparea padded" v-html="replyBody" name="body" id="body"
                                          @dragenter="dragger"
                                          @dragleave="dragger"
                                          @dragover="dragger"
                                          @drop="handleInlineFiles"></textarea>
                            </template> <!--  -->
                            <template v-else>
                                <textarea class="droparea padded" name="body" id="body"
                                          @dragenter="dragger"
                                          @dragleave="dragger"
                                          @dragover="dragger"
                                          @drop="handleInlineFiles"></textarea>
                            </template>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left padded">
                            Anhänge
                        </div>
                        <div class="form-right">
                            <div class="droparea padded"
                                 @dragenter="dragger"
                                 @dragleave="dragger"
                                 @dragover="dragger"
                                 @drop="handleFiles">
                                <template v-if="0 === files.length">
                                    <span>Anhänge hier ablegen</span>
                                </template>
                                <template v-else>
                                    <transition-group appear name="next" mode="out-in">
                                        <AttachmentPreview v-for="(file, index) in files" :file="file"
                                                           :key="'file-'+file.name"
                                                           @delete="handleFileDelete( index )"/>
                                    </transition-group>
                                </template>
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <div class="form-row">
                        <div class="form-left">
                            <div class="default-button default full" @click="$emit( 'close' )">
                                abbrechen
                            </div>
                        </div>
                        <div class="form-right">
                            <div class="default-button submit full" @click="handleReply()">
                                senden
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
            <Spinner v-else title="Hang on..." message="Deine Nachricht wird verschickt"/>
        </div>
    </transition>
</template>

<script>
import ErrorMessage      from '@/components/elements/ErrorMessage'
import Spinner           from '@/components/elements/Spinner'
import AttachmentPreview from '@/components/elements/helpdesk/AttachmentPreview'

export default {

    name      : 'MailEditor',
    components: { AttachmentPreview, Spinner, ErrorMessage },
    props     : {
        mail   : { Type: Object, required: false },
        headers: { Type: Object, required: false },
        rcp    : { Type: String, required: false }
    },

    data()
    {
        return {
            setup       : false,
            error       : false,
            cids        : [],
            errorMessage: false,
            processing  : false,
            recipient   : '',
            replyBody   : '',
            isReply     : false,
            html        : false,
            inline      : false,
            files       : [],
            senders     : {
                'mpohl'      : {
                    name: 'Manuel',
                    sign: 'Manuel von entzettelt'
                },
                'mplassmeier': {
                    name: 'Marc',
                    sign: 'Marc von entzettelt'
                },
                'bzweigner'  : {
                    name: 'Britta',
                    sign: 'Britta von entzettelt'
                }
            }
        }
    },

    emits: [ 'close' ],

    beforeDestroy()
    {
        this.files = []
    },

    created()
    {

        if( undefined !== this.$props.mail )
        {

            this.isReply = true

            this.recipient = ( undefined !== this.$props.headers.replyTo ? this.$props.headers.replyTo : this.mail.sender )

            this.replyBody = '\n\n' + this.$props.mail.sender + ' schrieb am ' + this.$props.mail.datetimeReceived + ':\n\n'
            this.replyBody += this.cutDebug( this.br2nl( decodeURIComponent( escape( atob( this.$props.mail.body ) ) ) ) )

            this.$nextTick()
                .then( () =>
                {
                    let area = document.querySelector( '#body' )
                    area.focus()
                } )
        }

        if( undefined !== this.$props.rcp )
        {
            this.recipient = this.$props.rcp
        }

    },

    methods: {

        cutDebug( message )
        {
            message = message.replace( /-{120}(.*\s\S){8}-{119}/g, '' )
            message = message.replace( /-{120}/g, '=====' )
            return message
        },

        br2nl( text )
        {
            text = text.replace( /<br\/>/g, '\n' )
            return text
        },

        nl2br( text )
        {
            text = text.replace( /\r\n/g, '<br/>', text )
            text = text.replace( /\n/g, '<br/>', text )
            return text
        },

        prepareAttachments()
        {

            return new Promise( resolve =>
            {

                let attachments = [],
                    done        = 0

                if( 0 === this.files.length )
                {
                    return resolve( attachments )
                }

                for( let f in this.files )
                {
                    let file   = this.files[ f ],
                        reader = new FileReader(),
                        att    = {}

                    reader.readAsBinaryString( file )
                    reader.onloadend = () =>
                    {
                        att.filename = file.name
                        att.contentType = file.type
                        att.content = reader.result
                        att.encoding = 'binary'
                        if( undefined !== file.cid )
                        {
                            att.cid = file.cid
                        }
                        attachments.push( att )
                        done++

                        if( done === this.files.length )
                        {
                            return resolve( attachments )
                        }

                    }
                }

            } )

        },

        /*eslint-disable*/
        handleReply()
        {

            this.prepareAttachments()
                .then( attachments =>
                {

                    let area = document.querySelector( '#body' )
                    let subject = document.querySelector( '#subject' )
                    let rcpElm = document.querySelector( '#recipient' )
                    let template = document.querySelector( '#template' )
                    let recipient = ( null !== rcpElm && '' !== rcpElm.value ) ? rcpElm.value : false

                    this.processing = true

                    let setup = {
                        from       : '"' + this.senders[ this.$store.getters.username ].sign + '" <kontakt@entzettelt.de>',
                        replyTo    : 'kontakt@entzettelt.de',
                        to         : false !== recipient ? recipient : ( undefined !== this.$props.headers && undefined !== this.$props.headers.replyTo ? this.$props.headers.replyTo : this.mail.sender ),
                        subject    : subject.value,
                        body       : area.value,
                        attachments: attachments,
                        inReplyTo  : ( undefined !== this.$props.headers ? this.$props.headers.messageId : undefined )
                    }

                    this.$core.getClient()
                        .request( {

                            method: 'admin.helpdesk_sendMail',
                            params: setup,
                            template: template.value

                        }, 30000 )
                        .then( () =>
                        {

                            this.processing = false
                            this.clearError()
                            this.$core.f().uiMessage( 'success', '<strong>Deine Nachricht ist auf dem Weg.</strong>' )
                            this.$emit( 'close', true )

                        } )
                        .catch( error =>
                        {

                            this.processing = false
                            this.error = 'Ooops: Das hat nicht funktioniert.'
                            this.errorMessage = 'Beim Versand der Nachricht ist ein Fehler aufgetreten: ' + error

                        } )

                } )

        },

        clearError()
        {
            this.error = false
            this.errorMessage = false
        },

        dragger( event )
        {

            event.preventDefault()
            event.stopPropagation()

            switch( event.type )
            {
                case 'dragleave':
                    event.target.classList.remove( 'highlighted' )
                    break
                default:
                    event.target.classList.add( 'highlighted' )
                    break
            }

        },

        appendFiles( file )
        {
            if( this.inline === true )
            {
                file.cid = this.$core.uuid().generate() + '@entzettelt.de'
                this.cids.push( file.cid )
            }
            this.files.push( file )
        },

        handleFiles( event )
        {

            event.preventDefault()
            event.stopPropagation()

            event.target.classList.remove( 'highlighted' )

            let dt = event.dataTransfer
            let files = dt.files;

            ( [ ...files ] ).forEach( this.appendFiles )

        },

        handleInlineFiles( event )
        {

            this.inline = true
            this.handleFiles( event, true )
            this.inline = false

            let body = document.querySelector( '#body' )

            while( 0 < this.cids.length )
            {
                let cid = this.cids.shift()
                body.value = body.value + ' <img src="cid:'+cid+'" width="100%" />'
            }

        },

        handleFileDelete( index )
        {

            let newFiles = []
            for( let f in this.files )
            {
                if( '' + index !== f )
                {
                    newFiles.push( this.files[ f ] )
                }
            }
            this.files = newFiles

        }

    }

}
</script>