<template>
    <div v-if="ready">
        <button @click="triggerBackups">Automatische Backups starten (kann ewig dauern)</button><br/>
        <button @click="triggerError">Random Fehler werfen (uncaught)</button>
    </div>
</template>

<script>
import Thd            from '../../../elements/Thd'
import ElementFilter  from '../../../elements/ElementFilter'
import Form           from '../../../elements/fullscreen/Form'
import Spinner        from '../../../elements/Spinner'
import MixinViewReady from '@/mixins/MixinViewReady'

export default {

    name: "ToolsToolkit",

    data()
    {
        return {
            ready: true
        }
    },

    created()
    {
    },

    methods: {

        triggerBackups()
        {
            this.$core
                .getClient()
                .request( {
                    method: 'admin.forceAutomaticBackup'
                } ).then( data =>
            {

                console.log( '>>> FORCE', data )
                alert( "Trigger ausgelöst" );

            } )
        },

        triggerError()
        {
            this.$core
                .getClient()
                .request( {
                    method: 'admin.testErrorHandler'
                } ).then( data =>
            {

                console.log( '>>> FORCE', data )
                alert( "Trigger ausgelöst" );

            } )
        }
    }
}
</script>