<template>
    <div :key="viewKey">
        <!--        {{ display }} {{ value }}-->
        <template v-for="( row, index ) in display"
                  :key="'row-'+index">
            <div class="row-adder-container">
                <template v-if="rowType === 'selectAndText'">
                    <div class="width-90">
                        <ColumnTypeSelector :value="row.columnType"
                                            name="columnType"
                                            @select="handleValue( index, row, 'columnType', undefined, $event )"/>
                        <div class="spacer"></div>
                        <input type="text" name="caption" :value="row.caption"
                               @change="handleValue( index, row, 'caption', $event, undefined )"
                               @input="handleValue( index, row, 'caption', $event, undefined )"/>
                        <input type="hidden" :id="'value_'+index" :value="fillSelectAndText( row, index )"/>
                    </div>
                    <div class="width-10">
                        <template v-if="index === ( display.length - 1 )">
                            <div class="button round large add" @click="add()">
                                +
                            </div>
                        </template>
                        <template v-else>
                            <div class="button round large delete" @click="remove( index )">
                                x
                            </div>
                        </template>
                    </div>
                    <div class="clearfix"></div>
                </template>
                <template v-else>
                    <div class="width-90">
                        <template v-if="'TextBox' === rowType">
                            <input type="text"
                                   :value="'create' !== row ? row : ''"
                                   @change="handleTextBoxValue( index, $event.target.value )"/>
                            <input type="hidden" :id="'value_'+index" :value="'create' !== row ? row : ''"/>
                        </template>
                        <template v-else>
                            <component :is="$core.f().ucFirst( rowType )"
                                       :value="row"
                                       :extended="setup.extended"
                                       @update="handleGenericValue( index, $event )"/>
                            <input type="hidden" :id="'value_'+index" :value="JSON.stringify( row.value )"/>
                        </template>
                    </div>
                    <div class="width-10">
                        <template v-if="index === ( display.length - 1 )">
                            <div class="button round large add" @click="add()">
                                +
                            </div>
                        </template>
                        <template v-else>
                            <div class="button round large delete" @click="remove( index )">
                                x
                            </div>
                        </template>
                    </div>
                    <div class="clearfix"></div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
import ColumnTypeSelector from '@/components/elements/formElements/ColumnTypeSelector'
import SubCompetence      from "@/components/elements/formElements/SubCompetence.vue"

export default {

    name      : 'RowAdder',
    components: { SubCompetence, ColumnTypeSelector },
    props     : [ 'value', 'id', 'rowType', 'setup' ],

    data()
    {
        return {
            viewKey: this.$core.uuid().generate(),
            ready  : true,
            display: []
        }
    },

    created()
    {
        this.display = this.$props.value ? JSON.parse( JSON.stringify( this.$props.value ) ) : []
        if( undefined === this.$props.value )
        {
            this.add()
        }
        else
        {
            try
            {
                for( let d in this.display )
                {

                    let clone = JSON.parse( JSON.stringify( this.display[ d ] ) )
                    delete clone.value
                    this.display[ d ].value = clone

                }
            }
                /*eslint-disable*/
            catch( e )
            {
            }
            /*eslint-enable*/

        }
    },

    beforeDestroy()
    {
        this.ready = false
    },

    methods: {

        handleValue( index, row, key, event, value )
        {
            value = value || event.target.value
            this.display[ index ][ key ] = value
            row[ key ] = value
            this.fillSelectAndText( row, index )

        },

        handleTextBoxValue( index, value )
        {
            value = value || undefined
            this.display[ index ] = this.display[ index ] !== 'create' ? this.display[ index ] : {}
            this.display[ index ] = value
            this.$emit( 'values', { id: this.$props.id, values: this.display } )
        },

        /*eslint-disable*/
        handleGenericValue( index, value )
        {
            value = value || undefined
            this.display[ index ] = this.display[ index ] !== 'create' ? this.display[ index ] : {}
            this.display[ index ] = value

/*            let clone = JSON.parse( JSON.stringify( this.display ) )
            delete clone.value
            this.display[ index ].value = clone*/

            this.$emit( 'values', { id: this.$props.id, values: this.display } )

        },

        fillSelectAndText( row, idx )
        {

            let value = {},
                setup = this.$props.setup

            value.id = row.id || idx

            if( setup.hidden.contains === true )
            {
                value[ setup.select.key ] = row[ setup.select.key ]
                value[ setup.text.key ] = row[ setup.text.key ]
            }

            for( let h in setup.hidden )
            {

                if( h !== 'contains' )
                {
                    if( 'object' === typeof ( setup.hidden[ h ] ) )
                    {
                        switch( setup.hidden[ h ].type )
                        {
                            case 'cleanId':
                                value[ h ] = idx + '_' + this.$core.getReformatter().cleanId( row[ setup.hidden[ h ].source ] )
                                break
                        }
                    }
                    else
                    {
                        value[ h ] = row[ h ]
                    }

                }

            }

            return JSON.stringify( value )
        },

        add()
        {
            this.display.push( JSON.parse( JSON.stringify( this.$props.setup.adder ) ) )
        },

        remove( index )
        {

            let newDisplay = []

            this.display.splice( index, 1 )
            for( let i in this.display )
            {
                newDisplay.push( this.display[ i ] )
            }

            this.display = newDisplay
            this.viewKey = this.$core.uuid().generate()

        }

    }

}
</script>