<template>
    <div>
        <input type="text" class="transparent" :id="id+'-title'" :ref="id+'-title'"
               placeholder="Bezeichnunng der Teilkompetenz"
               @change="handleChange" :value="title"/>
        <textarea class="small transparent" :id="id+'-description'" :ref="id+'-description'"
                  placeholder="Kompetenzformulierung wie im Zeugnis" @change="handleChange"
                  :value="description"></textarea>
        <template v-if="extended">
            <h4 style="margin:10px 0 0 0">Kompetenzen der Teilkompetenz</h4>
            <RowAdder :id="'subs-adder-'+id"
                      :value="subs || [ 'create' ]"
                      rowType="TextBox"
                      :setup="raSetup"
                      @values="handleSubs"/>
        </template>
        <input type="hidden" :id="id+'-localId'" :ref="id+'-localId'" :value="localId"/>
        <input type="hidden" :id="id+'-subs'" :ref="id+'-subs'" :value="subs"/>
    </div>
</template>

<script>

//import RowAdder from "@/components/elements/formElements/RowAdder.vue";

export default {

    name: 'SubCompetence',
//    components: { RowAdder },
    props: [ 'value', 'id', 'extended' ],
    emits: [ 'update' ],

    data()
    {
        return {
            ready      : true,
            localId    : false,
            title      : false,
            description: false,
            subs       : [ 'create' ],
            raSetup    : {
                value   : {
                    key: 'sub'
                },
                adder   : 'create',
                extended: true
            },
            raId       : 'competences_' + this.$props.id,
            raValue    : this.$props.value,
        }
    },

    created()
    {
        this.localId = this.$props.value ? this.$props.value.localId || this.$core.uuid().generate() : this.$core.uuid().generate()
        this.title = this.$props.value ? this.$props.value.title || '' : ''
        this.description = this.$props.value ? this.$props.value.description || '' : ''
        if( undefined !== this.$props.value.subs )
        {
            try
            {
                this.subs = JSON.parse( this.$props.value.subs )
            }
            catch( e )
            {
                this.subs = [ 'create' ]
            }
        }
    },

    mounted()
    {
    },

    beforeDestroy()
    {
        this.ready = false
    },

    methods: {

        handleSubs( event )
        {
            this.subs = JSON.stringify( event.values )
            this.handleChange()
        },

        handleChange()
        {

            let value = {
                localId    : this.localId,
                title      : this.$refs[ this.$props.id + '-title' ].value,
                description: this.$refs[ this.$props.id + '-description' ].value,
                subs       : this.$props.extended ? this.subs : undefined
            }

            this.title = this.$refs[ this.$props.id + '-title' ].value
            this.description = this.$refs[ this.$props.id + '-description' ].value

            this.$emit( 'update', JSON.parse( JSON.stringify( value ) ) )

        }

    }

}
</script>